<template lang="pug">
.btn-group
  label.btn.btn-default.btn-sm(:class="{active}", @click="toggle")
    span.glyphicon.glyphicon-ok &nbsp;
    | {{ 'filter.config.round_values' | i18n }}
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
  computed: {
    active() {
      return this.value !== false;
    },
  },
  methods: {
    toggle() {
      this.$emit("input", !this.active);
    },
  },
};
</script>

<style lang="scss" scoped>
.glyphicon-ok {
  color: #cccccc;
}
.active .glyphicon-ok {
  color: green;
}
</style>
