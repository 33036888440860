<template lang="pug">
.snippet-detail
  .snippet-chart-wrapper
    .panel.panel-snippet.detail-chart-panel
      #tour-grouping-select(style="width: 300px")
      .panel-body.spinner(v-if="!options")
        spinner
      drilldown-provider.panel-body(v-if="options")
        .flex-row.flex-wrap.toolbar
          dropdown-select(:value="grouping", :available-items="options", @update="onGroupingChanged")
          dropdown-select(
            v-if="type !== 'multi' && !isTrend",
            :value="sort",
            :available-items="sortOptions",
            @update="onSortChanged"
          )
            template(v-slot:toggle="slotProps")
              dropdown-select-button(:menu-open="slotProps.menuOpen", @click="slotProps.toggleMenu()")
                i.fa.mr-sm(:class="sortIcon")
                span.hidden-xs {{ sortLabel }}
          dropdown-select(
            v-if="singleComparison && filters.comparisons.length > 1",
            :value="selectedComparisonIndex",
            :available-items="comparisonOptions",
            @update="onSelectedComparisonChanged"
          )
          .flex-1
          drilldown-dropdown
          proportions-selector(v-if="proportionsEnabled", :value="proportions", @input="onUpdateProportions")
          a.btn.btn-default.btn-sm(@click="openReport") {{ "dashboard.show_more.view_report" | i18n }}

        hr.detailed-hr(style="margin-top: 10px; margin-bottom: 0px")

        .chart(v-if="chartType", :class="chartClass")
          trend(v-if="isTrend", :chart="chartType", :filters="effectiveFilters")
          .dashboard-block(v-else)
            chart.graph(
              :type="chartType",
              :pagination="true",
              :filters="effectiveFilters",
              @updateLimit="onUpdateLimit"
            )
</template>
<script lang="ts">
import spinner from "../components/spinner.vue";
import GroupingsApiService from "../api/groupings-api-service";
import trend from "./blocks/trend.vue";
import chart from "../zoined-chart/zoined-chart.vue";
import Actions from "../store/actions";
import Component from "vue-class-component";
import { Prop, Vue, Watch } from "vue-property-decorator";
import i18n from "../i18n";
import _ from "lodash";
import { OpenReportParams } from "../lib/report";
import DrilldownDropdown from "../drilldown/drilldown-dropdown.vue";
import DrilldownProvider from "../drilldown/drilldown-provider.vue";
import ProportionsSelector from "../components/proportions-selector.vue";
import DropdownSelect from "../components/dropdown-select.vue";
import DropdownSelectButton from "../components/dropdown-select-button.vue";
import MenuItem, { menuItemKey } from "../interfaces/menu-item";

@Component({
  components: {
    spinner,
    chart,
    trend,
    DrilldownDropdown,
    DrilldownProvider,
    ProportionsSelector,
    DropdownSelect,
    DropdownSelectButton,
  },
})
export default class DetailChartComponent extends Vue {
  @Prop()
  type: string;

  @Prop()
  chart: string;

  @Prop()
  caption: string;

  @Prop()
  filters: any;

  @Prop()
  metric: string;

  @Prop({ default: true })
  controls: boolean;

  groupings = null;
  chartType = null;
  selectedComparisonIndex = 0;
  targetReport = null;

  get preferences() {
    return this.$store.getters.preferences;
  }

  get metricPreferences() {
    return this.preferences.detail_chart_metric_preferences;
  }

  get proportionsEnabled() {
    return this.chart.indexOf("_trend") < 0;
  }

  get singleComparison() {
    return this.chart == "sales_trend";
  }

  get comparisonOptions() {
    return this.filters.comparisons.map((comparison, index) => ({
      key: index,
      name: comparison.text,
    }));
  }

  get selectedComparison() {
    return this.filters.comparisons[this.selectedComparisonIndex];
  }

  get effectiveFilters() {
    if (this.type === "multi") {
      let f = {
        grouping: _.flatten([this.filters.grouping, this.grouping]),
        proportions: !!this.proportions,
        top_grouping: this.grouping,
        limit: this.limit || 5,
      };

      const effectiveFilters = _.extend({}, this.filters, f);
      delete effectiveFilters["comparisons"];
      return effectiveFilters;
    } else {
      let limit = 10;
      if (this.filters && this.filters.comparisons && this.filters.comparisons.length >= 2) {
        limit = 5;
      }
      const effectiveFilters = Object.assign({}, this.filters, {
        grouping: this.grouping,
        sort: this.sort,
        limit: this.limit || limit,
        proportions: !!this.proportions,
      });

      if (this.chart === "sales_trend") {
        // Update filters
        if (this.selectedComparisonIndex) {
          effectiveFilters.comparisons = this.filters.comparisons[this.selectedComparisonIndex];
        }
      }

      return effectiveFilters;
    }
  }

  get chartClass() {
    const chart = this.chart;
    if (chart === "sales_trend" || chart === "visits_trend") {
      return "trend";
    } else if (this.type === "multi") {
      return "multi";
    } else {
      const comparisonCount = this.filters && this.filters.comparisons ? this.filters.comparisons.length : 0;
      return comparisonCount > 2
        ? "top_multi_series"
        : comparisonCount > 1
        ? "top_three_series"
        : comparisonCount == 1
        ? "top_two_series"
        : "top_one_series";
    }
  }

  get isTrend() {
    return this.chartClass === "trend";
  }

  get sortOptions(): MenuItem[] {
    return this.$store.getters
      .getParameters("sort")
      .map((sort) => ({ ...sort, icon: this.iconForSort(menuItemKey(sort)) }));
  }

  get options() {
    if (this.chart != null && this.groupings != null) {
      // Set options for grouping selector
      return this.groupings.map(function (key) {
        const name = i18n.t(`filter.config.${key}`, { defaultValue: key });
        return {
          key,
          name,
        };
      });
    } else {
      return null;
    }
  }

  get grouping() {
    if (!this.groupings) {
      return null;
    }
    const cachedGrouping = this.metricPreferences?.[this.metric]?.grouping;
    if (cachedGrouping != null && this.groupings.includes(cachedGrouping)) {
      return cachedGrouping;
    } else if (this.chart === "sales_trend") {
      return "category";
    } else if (this.chart === "visits_trend") {
      return "store";
    } else {
      const grouping = _.find(["product", "store", "sales_person", "store_country"], (grouping) =>
        this.groupings.includes(grouping)
      );
      if (grouping) {
        return grouping;
      } else {
        return this.options.length > 0 ? this.options[0].key : "store"; // No available groupings at all, try grouping by store, fingers crossed.
      }
    }
  }

  get sort() {
    return this.metricPreferences?.[this.metric]?.sort || "-metric";
  }

  get limit() {
    return this.metricPreferences?.[this.metric]?.limit;
  }

  get proportions() {
    return this.metricPreferences?.[this.metric]?.proportions;
  }

  get sortLabel() {
    return this.sortOptions.find((option) => menuItemKey(option) === this.sort)?.name;
  }

  get sortIcon() {
    return this.iconForSort(this.sort);
  }

  iconForSort(sort) {
    return {
      "-metric": "fa-sort-amount-desc",
      metric: "fa-sort-amount-asc",
      grouping: "fa-sort-alpha-asc",
    }[sort];
  }

  @Watch("metric")
  onMetricChange() {
    return this.fetchGroupings();
  }

  created() {
    this.setup();
    return this.fetchGroupings();
  }

  fetchGroupings() {
    return new GroupingsApiService()
      .getMetricGroupings(this.metric)
      .then((groupings) => (this.groupings = groupings.map(({ key }) => key)));
  }

  openReport() {
    // broadcast is listener by dashboard controller
    const proportionsConfig = this.proportions ? { proportions: true } : {};
    const config: OpenReportParams = {
      report: this.targetReport,
      grouping: this.grouping,
      metric: this.metric,
      limit: this.effectiveFilters.limit,
      ...proportionsConfig,
    };
    if (this.type !== "multi") {
      config.sort = this.sort;
    }
    this.$emit("open-report", config);
  }

  onGroupingChanged(grouping) {
    this.updateMetricPreferences("grouping", grouping);
  }

  onSortChanged(sort) {
    this.updateMetricPreferences("sort", sort);
  }

  onSelectedComparisonChanged(comparison) {
    this.selectedComparisonIndex = comparison;
  }

  updateMetricPreferences(key, value) {
    const metricPreferences = { ...(this.metricPreferences || {}) };
    const selectedMetricPreferences = metricPreferences[this.metric] || {};
    Object.assign(metricPreferences, {
      [this.metric]: {
        ...selectedMetricPreferences,
        [key]: value,
      },
    });
    this.$store.dispatch(Actions.updateDetailChartMetricPreferences, metricPreferences);
  }

  setup() {
    const chart = this.chart;
    if (chart === "sales_trend") {
      this.chartType = chart;
      this.targetReport = "sales_trend";
    } else if (chart === "visits_trend") {
      this.chartType = chart;
      this.targetReport = "people_counting_trend";
    } else {
      let chartName;
      if (this.type === "multi") {
        chartName = `multi_${chart}`;
      } else {
        chartName = `top_${chart}`;
      }
      this.chartType = chartName;
      this.targetReport = "organization_adhoc";
    }
  }

  onUpdateLimit(limit) {
    this.updateMetricPreferences("limit", limit);
  }

  onUpdateProportions(proportions) {
    this.updateMetricPreferences("proportions", proportions);
  }
}
</script>
<style lang="scss" scoped>
.snippet-detail {
  .panel-snippet {
    // Space for drilldown button
    padding-top: 50px;
  }
}

.toolbar {
  gap: 5px;
}
</style>
