import Comparison from "../model/comparison";
import MenuItem, { menuItemKey } from "../interfaces/menu-item";
import { comparisonToMenuKey } from "../lib/menu-helper";
import moment from "moment";

declare const I18n: any;
declare const _: any;

type TranslationServiceOpts = {
  metrics?: MenuItem[];
};

export default class TranslationService {
  opts: TranslationServiceOpts = {};

  constructor(opts: TranslationServiceOpts = {}) {
    this.opts = opts;
  }

  selectionTitle(selection, timePeriods = []): string {
    timePeriods = timePeriods || [];
    const item: any = timePeriods.find((item: any) => item.key === selection.type);

    let title;

    if (item && item.name) {
      title = item.name;
    } else if (selection) {
      switch (selection.type) {
        case "4_weeks":
        case "12_months":
          title = I18n.t("filters.date_selector.rolling_" + selection.type);
          break;
        default:
          title = I18n.t("filters.date_selector." + selection.type);
      }
    } else {
      return I18n.t("please_wait");
    }

    if (selection.series && selection.series != "actual") {
      title += ` (${this.seriesTitle(selection.series)})`;
    }

    return title;
  }

  comparisonTitle(
    comparison: Comparison,
    opts: { custom?: boolean; locale: string } = { custom: true, locale: I18n.locale }
  ) {
    const { scaling_modifier } = comparison;

    let title = this.comparisonBaseTitle(comparison, opts);

    if (scaling_modifier) {
      const percent = Math.round((scaling_modifier - 1) * 100);
      const sign = percent > 0 ? "+" : "";
      title += ` ${sign}${percent}%`;
    }

    return title;
  }

  comparisonBaseTitle(
    comparison: Comparison,
    opts: { custom?: boolean; locale: string } = { custom: true, locale: I18n.locale }
  ) {
    const { type, label, time_period, start_time, end_time } = comparison;

    // check if we have custom translation
    if (opts.custom !== false) {
      const customLabels = _.get(window, "zoinedContext.custom_labels.comparisons");
      if (customLabels) {
        const menuKey = comparisonToMenuKey(comparison);
        const locale = opts.locale || I18n.locale;
        if (customLabels[menuKey] && customLabels[menuKey][locale]) {
          return customLabels[menuKey][locale];
        }
      }
    }

    // return default translation
    switch (type) {
      case "custom":
        return this.dateRange(start_time, end_time);
      case "budget":
        return this.budgetTitle(label, opts);
      case "budget_full_month": {
        const benchmark = this.budgetTitle(label, opts);
        return capitalizeSentence(I18n.t("filters.benchmark.benchmark_full_month", { benchmark }));
      }
      case "time_period": {
        return this.selectionTitle({ type: time_period });
      }
      default:
        return I18n.t("filters.benchmark." + type, opts);
    }
  }

  seriesTitle(type) {
    return type == "actual" ? I18n.t("filters.date_selector.actual") : I18n.t(`filters.budgets.${type}`);
  }

  budgetTitle(label, opts) {
    label = label || "budget";
    return I18n.t("filters.budgets." + label, { ...opts, defaultValue: label });
  }

  dateRange(startTime, endTime) {
    return moment(startTime).format("ll") + " - " + moment(endTime).format("ll");
  }

  metricRangeTitle({ id, lower_limit, upper_limit, inclusive }) {
    let name;

    if (this.opts.metrics) {
      const metric = this.opts.metrics.find((item) => menuItemKey(item) === id);
      name = metric?.name;
    }

    name = name || I18n.t("metadata.metrics." + id);

    const parts: any = [];
    if (upper_limit == lower_limit) {
      parts.push(`= ${lower_limit}`);
    } else {
      if (lower_limit != null) {
        if (inclusive) {
          parts.push(`>= ${lower_limit}`);
        } else {
          parts.push(`> ${lower_limit}`);
        }
      }
      if (upper_limit != null) {
        if (inclusive) {
          parts.push(`<= ${upper_limit}`);
        } else {
          parts.push(`< ${upper_limit}`);
        }
      }
    }
    return `${name} ${parts.join(", ")}`;
  }
}

const capitalizeSentence = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
