<template lang="pug">
.public-report-page(v-if="config")
  h2.hide-new-layout {{ config.title }}
  zoined-report(
    :components="config.components" 
    :filter-configuration="config.filter_configuration" 
    :chart-options="config.chart_options" 
    :table-config="config.table_config" 
    :custom="config.custom"
    :public="true"
    )
.public-report-page(v-else)
  h2.text-center {{ 'errors.messages.page_not_found' | i18n }}
</template>

<script lang="ts">
import Mutations from "../store/mutations";
import Vue from "vue";
import Component from "vue-class-component";
import ZoinedReport from "../analytics/zoined-report.vue";

@Component({ components: { ZoinedReport } })
export default class PublicReportPage extends Vue {
  config: any = null;

  get reportContextState() {
    return this.$store.state.reportContext.state;
  }

  created() {
    if (!window.publicReportConfig) {
      return;
    }

    this.config = window.publicReportConfig;

    if (this.reportContextState === "initial" && window.reportContext) {
      this.$store.commit(Mutations.fetchReportContextSuccess, window.reportContext);
    }

    if (this.config.refresh) {
      // Refresh every 5 minutes
      window.setInterval(() => this.refresh(), 1000 * 300);
    }
  }

  refresh() {
    const config = this.config;
    this.config = null;
    window.setTimeout(() => (this.config = config));
  }
}
</script>
