<template lang="pug">
.table-column-selector
  .btn-group
    a.btn.btn-default.btn-sm#change-percent-button(:class="{active: config.changePercent}" v-uiv-tooltip.hover="changePercentTooltip" @click="toggleValue('changePercent')")
      span %± 
    a.btn.btn-default.btn-sm#absolute-change-button(:class="{active: config.absoluteChange}" v-uiv-tooltip.hover="changeAbsoluteTooltip" @click="toggleValue('absoluteChange')")
      span ±
    a.btn.btn-default.btn-sm#change-index-button(:class="{active: config.changeIndex}" v-uiv-tooltip.hover="changeIndexTooltip" @click="toggleValue('changeIndex')")
      | Ind
    a.btn.btn-default.btn-sm#proportions-button(:class="{active: config.proportions}" v-uiv-tooltip.hover="proportionTooltip" @click="toggleValue('proportions')")
      i.fa.fa-sm.fa-percent
</template>

<script lang="ts">
import Component from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";
import Vue from "vue";
import i18n from "../i18n";

export interface TableColumnSelectorConfig {
  changePercent: boolean;
  absoluteChange: boolean;
  changeIndex: boolean;
  proportions: boolean;
}

@Component({})
export default class TableColumnSelector extends Vue {
  @Prop()
  config: TableColumnSelectorConfig;

  get changePercentTooltip() {
    return i18n.t("report.snippet.table.change_percent");
  }

  get changeAbsoluteTooltip() {
    return i18n.t("report.snippet.table.change_absolute");
  }

  get changeIndexTooltip() {
    return i18n.t("report.snippet.table.change_index");
  }

  get proportionTooltip() {
    return i18n.t("report.snippet.table.proportion");
  }

  @Emit("change")
  toggleValue(key) {
    return {
      ...this.config,
      [key]: !this.config[key],
    };
  }
}
</script>

<style lang="scss" scoped>
.table-column-selector {
  display: inline-block;
}
</style>
