<template lang="pug">
.btn-group
  label.btn.btn-default.btn-sm(:class="{active: !!value}", @click="$emit('input', !value)")
    span.glyphicon.glyphicon-ok &nbsp;
    | {{ 'newsletter.definition.sections.common.chart_type.show_datalabels' | i18n }}
</template>
<script>
export default {
  props: {
    value: Boolean
  }
};
</script>

<style lang="scss" scoped>
.glyphicon-ok {
  color: #cccccc;
}
.active .glyphicon-ok {
  color: green;
}
</style>

