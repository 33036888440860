import _ from "lodash";
import { configToActive, refreshFilterTimes } from "../lib/filter-util";
import ReportType from "../model/report-type";
import store from "../store/store";
import i18n from "../i18n";
import { menuItemKey } from "../interfaces/menu-item";

export const reportTypes: ReportType[] = [
  {
    config: {
      report_type: "dashboard_snippet",
      components: [{ type: "dashboard_snippet", name: "dashboard_snippet", span: 6 }],
      filters: ["date", "comparisons", "limit_to_business_hours", "metrics", "like_for_like", "metric_type"],
      radio_selectors: ["metrics"],
      benchmark: ["avg_chain", "best_store", "avg_salesperson", "best_salesperson"],
      budgets_enabled: true,
      excel: true,
    },
    filters: {
      metric_type: {
        options: [
          { key: "value", name: i18n.t("filter.metric_type.value") },
          { key: "index", name: i18n.t("filter.metric_type.index") },
        ],
        default: "value",
      },
    },
  },
  {
    config: {
      report_type: "metric_chart",
      components: [{ type: "metric_chart", name: "metric_chart", extend_comparison: true }],
      filters: ["date", "comparisons", "limit_to_business_hours", "metrics", "like_for_like"],
      radio_selectors: ["metrics"],
      benchmark: ["avg_chain", "best_store", "avg_salesperson", "best_salesperson"],
      budgets_enabled: true,
      excel: true,
    },
  },
  {
    config: {
      report_type: "cross_table",
      components: [{ type: "snippet", name: "cross_table", pagination: true }],
      filters: [
        "date",
        "comparisons",
        "limit_to_business_hours",
        "grouping",
        "metrics",
        "like_for_like",
        "metric_range",
        "sort",
        "zerofill",
        "round_values",
      ],
      benchmark: ["avg_chain", "best_store", "avg_salesperson", "best_salesperson"],
      budgets_enabled: true,
      controls: { delta_values: true, round_values: true },
      radio_selectors: ["sort"],
      excel: true,
    },
    defaults: {
      limit: 100,
    },
  },
  {
    config: {
      report_type: "cross_tab_custom",
      components: [{ type: "snippet", name: "cross_tab_custom", pagination: true }],
      filters: [
        "date",
        "comparisons",
        "limit_to_business_hours",
        "grouping",
        "column_grouping",
        "metrics",
        "like_for_like",
        "sort",
        "zerofill",
        "round_values",
      ],
      benchmark: ["avg_chain", "best_store", "avg_salesperson", "best_salesperson"],
      budgets_enabled: true,
      controls: { delta_values: true, cross_tab_table_config: true, round_values: true },
      radio_selectors: ["column_grouping", "sort"],
      excel: true,
    },
    defaults: {
      limit: 100,
    },
  },
  {
    config: {
      report_type: "pie",
      components: [{ type: "chart", name: "pie", pagination: false, limit: true, span: 6 }],
      filters: ["date", "limit_to_business_hours", "grouping", "metrics", "variant", "selected_items", "show_values"],
      radio_selectors: ["metrics"],
      benchmark: ["avg_chain", "best_store", "avg_salesperson", "best_salesperson"],
      budgets_enabled: true,
      excel: true,
    },
    filters: {
      variant: {
        options: [
          { key: "top", name: i18n.t("chart.pie.variant.top") },
          { key: "selection", name: i18n.t("chart.pie.variant.selection") },
        ],
        default: "top",
      },
      selected_items: {
        options(filterConfiguration) {
          const { grouping = [] } = configToActive(filterConfiguration);

          const filters = store.getters.getParameters("filters") || [];

          // returns actually one Filter object for filter-selector
          return filters.find(({ id }) => id === grouping[0]);
        },
        enabled({ variant }) {
          return variant === "selection";
        },
      },
      show_values: {
        options: [
          { key: "percentage", name: i18n.t("dashboard.show_values.percentage") },
          { key: "values", name: i18n.t("dashboard.show_values.values") },
          { key: "both", name: i18n.t("dashboard.show_values.both") },
        ],
        default: "percentage",
      },
    },
    validate(filterConfiguration) {
      const { metrics = [], grouping = [], variant, selected_items = [] } = configToActive(filterConfiguration);
      return (
        metrics.length > 0 &&
        grouping.length > 0 &&
        (variant === "top" || (variant === "selection" && selected_items.length > 0))
      );
    },
    defaults: {
      limit: 5,
    },
  },
  {
    config: {
      report_type: "dual_axis",
      components: [{ type: "chart", name: "dual_axis", pagination: true }],
      filters: [
        "date",
        "comparisons",
        "limit_to_business_hours",
        "grouping",
        "metrics",
        "metrics2",
        "like_for_like",
        "metric_range",
        "sort",
        "zerofill",
      ],
      benchmark: ["avg_chain", "best_store", "avg_salesperson", "best_salesperson"],
      budgets_enabled: true,
      controls: { orientation: true, data_labels: true, proportions: true },
      radio_selectors: ["grouping", "sort"],
      excel: true,
    },
    filters: {
      like_for_like: {
        enabled: (filterConfiguration) => filterConfiguration.comparisons?.filter(({ enabled }) => enabled).length == 1,
      },
    },
  },
  {
    config: {
      report_type: "dual_axis_with_weather",
      components: [{ type: "chart", name: "dual_axis_with_weather", pagination: true }],
      filters: [
        "date",
        "comparisons",
        "limit_to_business_hours",
        "grouping",
        "metrics",
        "metrics2",
        "like_for_like",
        "metric_range",
        "sort",
        "zerofill",
      ],
      benchmark: ["avg_chain", "best_store", "avg_salesperson", "best_salesperson"],
      budgets_enabled: true,
      controls: { orientation: true, data_labels: true, proportions: true },
      radio_selectors: ["grouping", "sort"],
      excel: true,
      help_key: "weather",
      required_features: ["weather"],
    },
    filters: {
      like_for_like: {
        enabled: (filterConfiguration) => filterConfiguration.comparisons?.filter(({ enabled }) => enabled).length == 1,
      },
      grouping: {
        options() {
          return store.getters
            .getParameters("grouping")
            ?.filter((item) => ["store", "day"].includes(menuItemKey(item)));
        },
      },
    },
    validate(filterConfiguration) {
      const { grouping, store, start_time, end_time } = configToActive(refreshFilterTimes(filterConfiguration));
      return (grouping[0] === "day" && store?.length === 1) || (grouping[0] === "store" && start_time === end_time);
    },
    defaults: {
      selection: { type: "yesterday" },
    },
  },
  {
    config: {
      report_type: "stacked",
      components: [{ type: "chart", name: "stacked", pagination: true }],
      filters: [
        "date",
        "comparisons",
        "limit_to_business_hours",
        "grouping_x",
        "grouping_stack",
        "sort_stack",
        "metrics",
        "variant",
        "limit_y",
        "selected_items",
      ],
      radio_selectors: ["grouping_x", "grouping_stack", "sort_stack"],
      benchmark: ["avg_chain", "best_store", "avg_salesperson", "best_salesperson"],
      budgets_enabled: true,
      excel: true,
    },
    filters: {
      grouping_x: {
        default() {
          const groupings = store.getters.getParameters("grouping");
          return (
            groupings?.find(({ key }) => key == "store") && {
              store: { enabled: true },
            }
          );
        },
      },
      grouping_stack: {
        default() {
          const groupings = store.getters.getParameters("grouping");
          return (
            groupings?.find(({ key }) => key == "category") && {
              category: { enabled: true },
            }
          );
        },
      },
      variant: {
        default: "all",
        options: [
          { key: "all", name: i18n.t("filter.config.all") },
          { key: "top", name: i18n.t("filter.config.top_only") },
          { key: "top_other", name: i18n.t("filter.config.top_other") },
          {
            key: "selection",
            name: i18n.t("filter.config.selection_only"),
          },
          {
            key: "selection_other",
            name: i18n.t("filter.config.selection_other"),
          },
        ],
      },
      limit_y: {
        default: 20,
        options: [5, 10, 20, 30, 40].map((key) => ({
          key,
          name: key,
        })),
        enabled({ variant }) {
          return variant == "top" || variant == "top_other";
        },
      },
      selected_items: {
        options(filterConfiguration) {
          const { grouping_stack = [] } = configToActive(filterConfiguration);

          const filters = store.getters.getParameters("filters") || [];

          // returns actually one Filter object for filter-selector
          return filters.find(({ id }) => id === grouping_stack[0]);
        },
        enabled(filterConfiguration) {
          const { grouping_stack, variant } = configToActive(filterConfiguration);
          return grouping_stack?.length > 0 && (variant == "selection" || variant == "selection_other");
        },
      },
    },
    validate(filterConfiguration) {
      const { metrics = [], grouping_x = [], grouping_stack = [], variant, selected_items = [] } = configToActive(
        filterConfiguration
      );

      return (
        metrics.length > 0 &&
        grouping_x.length > 0 &&
        grouping_stack.length > 0 &&
        (variant == "selection" || variant == "selection_other" ? selected_items.length > 0 : true)
      );
    },
    defaults: {
      limit: 10,
    },
  },
  {
    config: {
      report_type: "analysis_4d",
      components: [{ type: "chart", name: "analysis_4d", pagination: true }],
      filters: ["date", "limit_to_business_hours", "grouping", "metrics_x", "metrics_y", "metrics_z"],
      radio_selectors: ["metrics_x", "metrics_y", "metrics_z"],
      benchmark: ["avg_chain", "best_store", "avg_salesperson", "best_salesperson"],
      budgets_enabled: true,
      excel: true,
    },
    filters: {
      metrics_x: {
        default() {
          const metrics = store.getters.getParameters("metrics");
          return (
            metrics?.find(({ key }) => key == "sales") && {
              sales: { enabled: true },
            }
          );
        },
      },
      metrics_y: {
        default() {
          const metrics = store.getters.getParameters("metrics");
          return (
            metrics?.find(({ key }) => key == "sales_pcs") && {
              sales_pcs: { enabled: true },
            }
          );
        },
      },
      metrics_z: {
        default() {
          const metrics = store.getters.getParameters("metrics");
          return (
            metrics?.find(({ key }) => key == "order_count") && {
              order_count: { enabled: true },
            }
          );
        },
      },
    },
    validate(filterConfiguration) {
      const { grouping, metrics_x, metrics_y, metrics_z } = configToActive(filterConfiguration);

      return _.every([grouping, metrics_x, metrics_y, metrics_z], (filter) => filter?.length > 0);
    },
  },
  {
    config: {
      report_type: "heatmap",
      components: [{ type: "chart", name: "heatmap", pagination: true }],
      filters: [
        "date",
        "limit_to_business_hours",
        "grouping_x",
        "grouping_y",
        "metrics",
        "sort_x",
        "sort_y",
        "variant",
        "limit_y",
        "proportions_set",
      ],
      radio_selectors: ["grouping_x", "grouping_y", "metrics", "sort_x", "sort_y"],
      benchmark: ["avg_chain", "best_store", "avg_salesperson", "best_salesperson"],
      budgets_enabled: true,
      excel: true,
    },
    filters: {
      grouping_x: {
        default: { day: { enabled: true } },
      },
      grouping_y: {
        default: { hour: { enabled: true } },
      },
      variant: {
        default: "all",
        options: [
          { key: "all", name: i18n.t("filter.config.all") },
          { key: "top", name: i18n.t("chart.pie.variant.top") },
        ],
      },
      limit_y: {
        default: 20,
        options: [5, 10, 20, 30, 40].map((key) => ({
          key,
          name: key,
        })),
        enabled: ({ variant }) => variant == "top",
      },
      proportions_set: {
        default: "all",
        options: [
          { key: "all", name: i18n.t("filter.config.all") },
          { key: "per_x", name: i18n.t("filter.proportions_set.per_x") },
          { key: "per_y", name: i18n.t("filter.proportions_set.per_y") },
        ],
      },
    },
    defaults: {
      limit: 30,
    },
  },
  {
    config: {
      report_type: "sales_trend",
      components: [
        { type: "chart", name: "sales_trend", pagination: true, filter: { limit: 5 } },
        { type: "chart", name: "sales_trend_bottom", pagination: true, filter: { limit: 5 } },
      ],
      filters: ["date", "comparisons", "limit_to_business_hours", "grouping", "metrics"],
      excel: true,
    },
    filters: {
      grouping: {
        options() {
          return store.getters.getParameters("grouping")?.filter((item) => item.category_key !== "Time");
        },
      },
    },
  },
];
