<template lang="pug">
report-view(v-if="loaded", :key="$route.path")
spinner(v-else)
</template>

<script lang="ts">
import ReportView from "./report-view.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import spinner from "../components/spinner.vue";
import Actions from "../store/actions";
import Mutations from "../store/mutations";
import toaster from "toastr";
import i18n from "../i18n";
import { sendMixpanelEvent } from "@/mixpanel";

@Component({
  components: {
    ReportView,
    spinner,
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit(Mutations.clearReportContext);
    next();
  },
})
export default class ReportPage extends Vue {
  @Prop()
  name: string;

  @Prop()
  myReportId: string;

  get reportContextState() {
    return this.$store.state.reportContext.state;
  }

  get loaded() {
    return this.reportContextState === "loaded";
  }

  created() {
    // Do we have reportContext passed from page request
    if (this.reportContextState === "initial" && window.reportContext) {
      this.$store.commit(Mutations.fetchReportContextSuccess, window.reportContext);
    }
    // If not then fetch context
    else {
      return this.$store
        .dispatch(Actions.fetchAnalyticsReport, { report_name: this.name, my_report_id: this.myReportId })
        .catch((error) => {
          if (error?.response?.status === 404) {
            this.$router.push("/");
          } else {
            toaster.error(i18n.t("errors.loading_failed"));
            throw error;
          }
        });
    }

    sendMixpanelEvent("Opened Report", { Type: this.name });
  }
}
</script>
