<template lang="pug">
.btn-group.flex-row
  label.btn.btn-default.btn-sm(:class="{active: !value}", @click="$emit('input', false)")
    | {{ 'dashboard.show_values.values' | i18n }}
  label.btn.btn-default.btn-sm(:class='{active: value}', @click="$emit('input', true)")
    | {{ 'dashboard.show_values.percentage' | i18n }}
</template>
<script>
export default {
  props: {
    value: Boolean
  }
};
</script>

