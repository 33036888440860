<template lang="pug">
btn-group
  btn.btn-sm(type="primary", input-type="radio", :input-value="true", :value="value", @click="update(true)") {{ trueTitle }}
  btn.btn-sm(type="primary", input-type="radio", :input-value="false", :value="value", @click="update(false)") {{ falseTitle }}
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";
import i18n from "../i18n";
import { Btn, BtnGroup } from "uiv";

@Component({
  props: {
    value: Boolean,
    trueTitle: {
      type: String,
      default: i18n.t("options.yes"),
    },
    falseTitle: {
      type: String,
      default: i18n.t("options.no"),
    },
  },
  components: {
    Btn,
    BtnGroup,
  },
})
export default class ToggleButton extends Vue {
  update(value) {
    this.$emit("input", value);
  }
}
</script>
