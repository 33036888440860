<template lang="pug">
  modal(id="add-component-modal",
    v-model="open",
    :title="title",
    )
    spinner(v-if="loading")   

    .row(v-else)
      div(class="editor-left-bar")
        ul(class="nav nav-pills nav-stacked")
          li(role="presentation", :class="{ 'active': tab === 'general'}")
            a(@click='tab ="general"' class="left-link")
              i(class="fa fa-bar-chart left-icon")
              p(class="left-icon-text") {{ 'dashboard_custom.report_editor.general' | i18n }} 
          li(role="presentation", :class="{ 'active': tab === 'filters'}")
            a(@click='tab ="filters"' class="left-link")
              i(class="fa fa-sliders left-icon")
              p(class="left-icon-text") {{ 'dashboard_custom.report_editor.filters' | i18n }} 
          li(role="presentation", v-if="shared", :class="{ 'active': tab === 'settings'}")
            a(@click='tab = "settings"' class="left-link") 
              i(class="fa fa-cog left-icon")
              p(class="left-icon-text") {{ 'dashboard_custom.report_editor.settings' | i18n }} 

      div(class="editor-container container")
        div(class="editor-right-bar col-md-6 col-xs-12")
          div(v-show="tab === 'general'" class=`tab-content ${tab}`)
            div(class="col-md-12")
              h4 {{ 'dashboard_custom.report_editor.name' | i18n }} 
              input(
                v-model="component.custom_title"
                placeholder="Add name (optional)",
                type="text"
                class="form-control name-input"
                )

            div(class="type-container col-md-12")
              h4 {{ 'dashboard_custom.report_editor.component_type' | i18n }} 
              div(
                v-for="(comp, type) in availableComponents" 
                class="component type-item" 
                :class="{ 'selected': comp.name == component.name }"
                @click="selectComponentType(type)")
                div(class="chartImg", v-bind:class="comp.name.replace(/[^a-zA-Z]/g, '')")
                p(class="type-text") {{ 'dashboard_custom.report_editor.component_types.' + comp.name | i18n }} 

            div(class="col-md-12")
              h4 {{ 'dashboard_custom.report_editor.layout' | i18n }}
              #select-layout
                .btn-group
                  label.btn.btn-primary(v-if="isSpanEnabled(12)", :class="{ 'active': component.span === 12}", @click="setConfig('span', 12)") {{ 'dashboard_custom.report_editor.full' | i18n }} 
                  label.btn.btn-primary(v-if="isSpanEnabled(6)", :class="{ 'active': component.span === 6}", @click="setConfig('span', 6)") {{ 'dashboard_custom.report_editor.half' | i18n }} (1/2)
                  label.btn.btn-primary(v-if="isSpanEnabled(4)", :class="{ 'active': component.span === 4}", @click="setConfig('span', 4)") {{ 'dashboard_custom.report_editor.third' | i18n }} (1/3)
                  label.btn.btn-primary(v-if="isSpanEnabled(3)", :class="{ 'active': component.span === 3}", @click="setConfig('span', 3)") {{ 'dashboard_custom.report_editor.quarter' | i18n }} (1/4)

            div(class="col-md-12")
              h4 {{ 'dashboard_custom.report_editor.configuration' | i18n }}            
              div
                span(v-for="widget in widgetConfigs", v-if="widgetEnabled(widget)")
                  config-pill-list(v-if="widgetComponent(widget) == 'config-pill-list'", :title="widgetTitle(widget)", :radio="!!widget.radio", :draggable="widget.draggable", :config="componentWidgetConfig[widget.key]", :available-items="widgetOptions(widget)" @update="updateWidgetConfig(widget.key, $event)")
                  filter-dropdown(v-if="widgetComponent(widget) == 'filter-dropdown'", :title="widgetTitle(widget)", :available-items="widgetOptions(widget)", :value="componentWidgetConfig[widget.key]", @update="updateWidgetConfig(widget.key, $event)")
                  filter-selector(v-if="widgetComponent(widget) == 'filter-selector'", :excludable="false", :config="componentWidgetConfig[widget.key]", :filter="widgetOptions(widget)", @update="updateWidgetConfig(widget.key, $event)")
                  zerofill-selector(v-if="widgetComponent(widget) == 'zerofill'" :value="componentWidgetConfig[widget.key]" @input="updateWidgetConfig(widget.key, $event)")
          div(v-show="tab === 'filters'" class=`tab-content ${tab}`)
            div(class="col-md-12")
              h4 {{ 'dashboard_custom.report_editor.filters' | i18n }}            
              div(style="margin-bottom: 10px;")
                time-period-dropdown(:available-items="options.time_ranges", :available-series-types="options.seriesTypes", :value="filters.selection", :default="filterConfiguration.selection", @update="updateFilter('selection', $event)")
                comparison-pill-list(v-if="!forceSelected.comparisons", :comparisons="filters.comparisons", :defaults="filterConfiguration.comparisons", @update="updateFilter('comparisons', $event)")
                business-hours-selector(:value="filters.limit_to_business_hours", :default="!!filterConfiguration.limit_to_business_hours", @update="updateFilter('limit_to_business_hours', $event)")
                span(v-for="widget in widgetConfigs")
                  .metric-range(v-if="widgetComponent(widget) == 'metric-range' && widgetEnabled(widget)")
                    metric-range-selector(:items="filters.metric_range||[]", @update="updateFilter('metric_range', $event)")
                    rangefilter-union-selector(v-if="filters.metric_range && filters.metric_range.length >= 2" :value="filters.rangefilter_union"  @input="updateFilter('rangefilter_union', $event)")
                  like-for-like-selector(v-if="widgetComponent(widget) == 'like-for-like'", :disabled="!widgetEnabled(widget)", :config="componentWidgetConfig.like_for_like", @update="updateWidgetConfig('like_for_like', $event)")
              hr
              filterset-selector(:config="filtersetConfiguration", @update="updateFilterset($event)")
              .filter-container
                filter-selector(:config="filters[filter.id]", :defaultConfig="defaults[filter.id]", :filter="filter", @update="updateFilter(filter.id, $event)", v-for="filter in options.filters" :key="filter.id")
              .btn.btn-primary(v-if="hasFilterOverrides", style="margin-top: 20px", @click="restoreFilterDefaults()") {{ 'actions.restore_defaults' | i18n }}

          div(v-show="tab === 'settings'" class=`tab-content ${tab}`)
            div(class="col-md-12" v-if="isAdmin")
              h4 {{ 'dashboard_custom.report_editor.static' | i18n }} 
              #select-layout
                .btn-group
                  label.btn.btn-primary(:class="{ 'active': !!component.static}", @click="setConfig('static', true)") {{ 'dashboard_custom.report_editor.yes' | i18n }}
                  label.btn.btn-primary(:class="{ 'active': !component.static}", @click="setConfig('static', false)") {{ 'dashboard_custom.report_editor.no' | i18n }}
                .selection-info {{ staticInfoText }}

        div(class="editor-preview col-md-6 col-xs-12")
          div(v-if="componentReady", class="preview-wrapper")
            zoined-report-component(
              :component="updatedComponent",
              :filterConfiguration="filterConfiguration",
              :show-filter-configuration="true",
              :chartOptions="{ custom: true }",
              :customReport="true",
              :preview="true",
              @componentUpdated="updateComponent"
            )

          .help-block(v-else-if="component.help_key")
            i.fa.fa-info-circle.mr-sm
            span {{ "report.help." + component.help_key | i18n }}

    div(slot="footer")
      button.btn.btn-default(@click="open = false") {{ 'actions.cancel' | i18n }}
      button.btn.btn-primary(:disabled="!componentReady", @click="addComponentAndHideModal") {{ 'actions.save' | i18n }}
</template>

<script>
import { componentFilters, WIDGET_FILTERS } from "../lib/filter-util";
import { Modal } from "uiv";
import vSelect from "vue-multiselect";
import Vue from "vue";
import I18n from "../i18n";
import _ from "lodash";

Vue.component("v-select", vSelect);

import zoinedReportComponent from "../analytics/zoined-report-component.vue";
import filterComponent from "./filter-component.vue";
import groupingComponent from "./grouping-component.vue";
import TimePeriod from "../filters/time-period.ts";
import spinner from "../components/spinner.vue";
import pillList from "../components/pill-list";
import FilterDropdown from "../components/filter-dropdown";
import comparisonPillList from "../components/comparison-pill-list";
import filterSelector from "../components/filter-selector";
import timePeriodDropdown from "../components/time-period-dropdown";
import businessHoursSelector from "../components/business-hours-selector";
import metricRangeSelector from "../components/metric-range-selector";
import rangefilterUnionSelector from "../components/rangefilter-union-selector.vue";
import likeForLikeSelector from "../components/like-for-like-selector";
import configPillList from "../components/config-pill-list";
import filtersetSelector from "../components/filterset-selector";
import zerofillSelector from "../components/zerofill-selector";
import { availableComponents } from "./component-types";
import { buildComponent } from "./utils";

const widgetFilters = [
  ...WIDGET_FILTERS,
  "variant",
  "limit",
  "limit_y",
  "selected_items",
  "proportions_set",
  "like_for_like",
  "zerofill",
  "trend",
  "show_values",
  "metric_type",
];

export default {
  name: "addComponentModal",
  props: {
    addComponent: Function,
    setComponent: Function,
    options: Object,
    filterConfiguration: Object,
    shared: Boolean,
  },
  components: {
    spinner,
    zoinedReportComponent,
    filterComponent,
    groupingComponent,
    pillList,
    FilterDropdown,
    Modal,
    comparisonPillList,
    filterSelector,
    timePeriodDropdown,
    businessHoursSelector,
    metricRangeSelector,
    rangefilterUnionSelector,
    likeForLikeSelector,
    configPillList,
    filtersetSelector,
    zerofillSelector,
  },
  data() {
    return {
      open: false,
      componentWidgetConfig: {},
      filters: {},
      sets: [],
      loading: false,
      snippet: false,
      metrics: null,
      grouping: [],
      selectedMetrics: null,
      selectedMetrics2: null,
      custom_title: null,
      tab: "general",
      component: {
        custom_title: null,
        type: "",
        name: "",
        pagination: true,
        span: 6,
        static: false,
      },
      filterOptions: {},
      isAdmin: window.zoinedContext.isAdmin,
    };
  },
  computed: {
    availableComponents() {
      return _.pickBy(availableComponents, (component) => {
        return _.every(component.required_features, (feature) => window.zoinedContext.features[feature]);
      });
    },
    spanOptions() {
      if (this.component.spanOptions) {
        return this.component.spanOptions;
      } else {
        return [6, 12];
      }
    },
    currentGrouping() {
      return _.map(this.componentWidgetConfig.grouping, ({ enabled }, key) => ({
        key,
        enabled,
      }))
        .filter(({ enabled }) => enabled)
        .map(({ key }) => key)[0];
    },
    groupingFilter() {
      return _.find(this.options.filters, ({ id }) => id == this.currentGrouping);
    },
    limitOptions() {
      return (this.component.limitOptions || [2, 3, 4, 5, 10]).map((key) => ({
        key,
        name: key,
      }));
    },
    groupingPlaceHolder() {
      return I18n.t("chart.drilling_to");
    },
    title() {
      if (this.selectedComponentType) {
        return I18n.t("dashboard_custom.report_editor.component_title"); // "Select component type";
      } else {
        return I18n.t("dashboard_custom.report_editor.component_title_else"); // "Select component presets"
      }
    },
    componentReady() {
      if (!this.component || !this.component.name) {
        return false;
      }

      if (this.component.validate) {
        return this.component.validate(this);
      } else {
        return (
          (!this.widgets.metrics || this.isPresent("metrics")) && (!this.widgets.grouping || this.isPresent("grouping"))
        );
      }
    },
    // Time selection configured in component
    componentSelection() {
      return this.filters.selection;
    },
    componentComparisons() {
      return this.forceSelected.comparisons || this.filters.comparisons;
    },
    effectiveSelection() {
      return this.componentSelection || this.filterConfiguration.selection;
    },
    effectiveComparisons() {
      return this.componentComparisons || this.filterConfiguration.comparisons;
    },
    effectiveFilters() {
      // This is used from component-types
      const { currency } = window.zoinedContext.currency;
      return {
        ...componentFilters(this.filterConfiguration, this.updatedComponent),
        currency,
      };
    },
    componentFilterConfiguration() {
      const cfg = {};

      const supportedWidgets = this.widgetConfigs.filter((widget) => this.widgetEnabled(widget)).map(({ key }) => key);

      const componentWidgetConfig = _.pickBy(
        this.componentWidgetConfig,
        (config, key) =>
          (supportedWidgets.includes(key) || (key == "limit" && this.componentConfig.pagination)) && config !== null
      );

      Object.assign(cfg, this.component.defaults, componentWidgetConfig, this.filters);

      const _comparisons = this.forceSelected.comparisons || this.filters.comparisons;

      if (_comparisons) {
        cfg.comparisons = _comparisons.map(({ enabled, ...comparison }) => {
          const comparisonTimePeriod = new TimePeriod({
            selection: this.effectiveSelection,
            comparison: comparison,
          }).getFilterModel();
          return { ...comparison, ...comparisonTimePeriod.comparison, enabled };
        });
      }

      if (!_.isEmpty(this.sets)) {
        cfg.sets = this.sets;
      }

      return cfg;
    },
    updatedComponent() {
      return {
        ...this.component,
        filterConfiguration: {
          ...this.componentFilterConfiguration,
        },
      };
    },
    filtersetConfiguration() {
      return {
        v: 2,
        sets: this.sets,
        filters: this.filters,
      };
    },
    hasFilterOverrides() {
      return !_.isEmpty(this.filters);
    },
    componentConfig() {
      return this.availableComponents[this.component.name] || this.availableComponents[this.component.type];
    },
    widgetConfigs() {
      const configs = (this.componentConfig && this.componentConfig.widgets) || [];
      return configs.map((config) => (_.isString(config) ? { key: config } : config));
    },
    widgets() {
      return _.fromPairs(this.widgetConfigs.map((config) => [config.key, config]));
    },
    forceSelected() {
      return (this.componentConfig && this.componentConfig.forceSelected) || {};
    },
    defaults() {
      const defaults = _.reduce(
        this.filterConfiguration,
        (result, config, key) => {
          switch (key) {
            case "selection":
            case "comparisons":
              return result;
            default:
              return {
                ...result,
                [key]: _.fromPairs(
                  _.map(config, ({ enabled, exclude, value, name }, key) => [
                    key,
                    {
                      value: key,
                      enabled,
                      exclude: !!exclude,
                      name: name || value || key,
                    },
                  ])
                ),
              };
          }
        },
        {}
      );
      return defaults;
    },
    staticInfoText() {
      const key = this.component.static
        ? "dashboard_custom.report_editor.static_enabled_info"
        : "dashboard_custom.report_editor.static_disabled_info";

      return I18n.t(key);
    },
  },
  methods: {
    isPresent(key) {
      const config = this.componentWidgetConfig[key];
      return config && !_.isEmpty(_.filter(config, ({ enabled }) => enabled));
    },
    isSpanEnabled(span) {
      return this.spanOptions.includes(span);
    },
    filterKey(key) {
      return this.widgets[key].filterKey || key;
    },
    widgetEnabled(widget) {
      return (
        widget &&
        (widget.enabled === true ||
          _.isUndefined(widget.enabled) ||
          (_.isFunction(widget.enabled) && widget.enabled(this)))
      );
    },
    widgetTitle({ title, key }) {
      return title || I18n.t(`filter.config.${key}`);
    },
    widgetOptions({ options, key }) {
      return (_.isFunction(options) && options(this)) || (!_.isFunction(options) && options) || this.options[key];
    },
    widgetComponent({ component }) {
      return component || "config-pill-list";
    },
    updateComponent(component) {
      const { filterConfiguration, chartOptions, tableConfig, highchartOptions } = component || {};

      if (chartOptions) {
        this.component.chartOptions = chartOptions;
      }

      if (tableConfig) {
        this.component.tableConfig = tableConfig;
      }

      if (highchartOptions) {
        this.component.highchartOptions = highchartOptions;
      }

      if (filterConfiguration) {
        this.filters = _.omit(filterConfiguration, widgetFilters);
        const componentWidgetConfig = _.pick(filterConfiguration, widgetFilters);
        this.$set(this, "componentWidgetConfig", componentWidgetConfig);
      }

      this.highchartOptions = highchartOptions;
    },
    updateFilter(filter, config) {
      this.filters = { ...this.filters, [filter]: config };
    },
    updateFilterset({ sets, filters }) {
      this.sets = sets;
      this.filters = filters;
    },
    updateWidgetConfig(widget, config) {
      Vue.set(this.componentWidgetConfig, widget, config);
    },
    restoreFilterDefaults() {
      this.filters = {};
    },
    setMetrics(metrics) {
      this.selectedMetrics = [];
      if (metrics && Object.keys(metrics).length > 0) {
        this.selectedMetrics = Object.keys(metrics).map((metric) => {
          return {
            label: metrics[metric]["value"],
            value: metric,
          };
        });
      }
    },
    setGrouping(groupings) {
      this.grouping = null;
      if (groupings && Object.keys(groupings).length > 0) {
        this.grouping = Object.keys(groupings).map((grouping) => {
          return {
            label: groupings[grouping]["value"],
            value: grouping,
          };
        });
      }
    },
    selectComponentType(type) {
      this.component = _.cloneDeep(this.availableComponents[type]);
      this.setWidgetConfigDefaults();
    },
    setConfig(key, value) {
      Vue.set(this.component, key, value);
    },
    addComponentAndHideModal() {
      if (this.edit >= 0) {
        this.setComponent(this.updatedComponent, this.edit);
      } else {
        this.addComponent(this.updatedComponent);
      }
      this.open = false;
    },
    showAddComponentModal(components, index) {
      this.tab = "general";

      if (components && index >= 0) {
        this.edit = index;
        const component = components[index];
        this.component = buildComponent(component);

        this.filters = _.omit(this.component.filterConfiguration, [...widgetFilters, "sets"]);

        this.sets = this.component.filterConfiguration.sets || [];

        if (this.component.name == "cross_table" || this.component.name == "cross_tab_custom") {
          this.updateComponent(this.component);
        }
      } else {
        this.edit = -1;
        this.filters = {};
        this.sets = [];
        this.selectComponentType("dashboard_snippet");
      }

      this.setWidgetConfigDefaults();

      this.open = true;
    },

    setWidgetConfigDefaults() {
      const defaultMetric = (
        _.find(this.options.metrics, (metric) => metric.id === "sales") || _.first(this.options.metrics)
      ).id;

      const defaultGrouping = (
        _.find(this.options.groupings, (grouping) => grouping.key === "store") || _.first(this.options.groupings)
      ).key;

      const defaultColumnGrouping = (
        _.find(this.options.groupings, (grouping) => grouping.key === "weekday") || _.first(this.options.groupings)
      ).key;

      const defaultWidgetConfig = {
        metrics: { [defaultMetric]: { enabled: true } },
        grouping: { [defaultGrouping]: { enabled: true } },
        column_grouping: { [defaultColumnGrouping]: { enabled: true } },
      };

      const componentWidgetConfig = _.reduce(
        widgetFilters,
        (config, key) => {
          let value;

          if (this.widgets[key]) {
            value =
              this.widgets[key] &&
              (_.get(this.component.filterConfiguration, key) ||
                (_.isFunction(this.widgets[key].default) && this.widgets[key].default(this)) ||
                (!_.isFunction(this.widgets[key].default) && this.widgets[key].default) ||
                defaultWidgetConfig[key]);
          } else if (key == "limit") {
            value = _.get(this.component.filterConfiguration, "limit");
          }

          return value ? { ...config, [key]: value } : config;
        },
        {}
      );

      this.$set(this, "componentWidgetConfig", componentWidgetConfig);
    },
  },
  watch: {
    currentGrouping(newGrouping, oldGrouping) {
      if (oldGrouping) {
        Vue.set(this.componentWidgetConfig, "selected_items", null);
      }
    },
    updatedComponent: {
      deep: true,
      handler(newConfig, oldConfig) {
        if (this.componentConfig.onComponentChanged) {
          this.componentConfig.onComponentChanged(this, newConfig, oldConfig);
        }
      },
    },
  },
};
</script>

// Without scope for all modals

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.modal-open {
  overflow: initial !important;
  padding-right: 0px !important;
}

.modal-backdrop {
  opacity: 0.5;
}

.metric-select-list {
  margin: 10px;
}

.name-input {
  height: 40px !important;
}

.component.type-item {
  width: 135px;
  display: inline-block;
  vertical-align: top;
}

.chartImg {
  background-size: contain;
}

.component.type-item.selected .chartImg {
  /* border: 1px solid #4bcdf0; */
  /* border-radius: 5px;
  background-color: #ddd; */
  border-color: #4bcdf0;
  border-style: solid !important;
}

.type-item.selected p {
  color: #4bcdf0;
}

.type-text {
  text-align: center;
}

#add-component-modal {
  margin: 20px;
  padding-left: 0;
}

#add-component-modal .modal-dialog {
  max-width: 1400px;
  margin: auto;
  width: 100%;
  height: 100%;
  padding: 0;
  overflow-y: initial !important;
}

#add-component-modal .modal-dialog .modal-content .modal-body {
  padding-bottom: 0;
  padding-top: 0;
}

.editor-container {
  height: calc(100vh - 159px);
  overflow-y: auto;
}

.multiselect__tag {
  background: #4bcdf0;
}

.multiselect__tag-icon:hover {
  background: #4bcdf0;
}
</style>

<style scoped lang="scss">
.component {
  list-style-type: none;
  margin: 5px;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  color: #fff;
  background-color: #4bcdf0;
}

.component:hover {
  cursor: pointer;
  color: #4bcdf0;
}

.metric-list {
  position: absolute;
}

.editor-left-bar {
  padding: 0;
  position: absolute;
  height: 100%;
  background-color: #f9f9f9;
  border-right: 1px solid #f3efef;
}

.editor-container {
  width: inherit; // 100%;
  display: block;
  margin-left: 80px;
}

.editor-right-bar,
.editor-preview {
  float: left;
}

.editor-preview {
  position: relative;
  z-index: 0;
  padding-top: 2rem;
}

.left-icon {
  font-size: 24px;
}

.left-icon-text {
  font-size: 12px;
  margin: 0;
}

.left-link {
  border-radius: 0px !important;
  padding: 10px;
  text-align: center;
}

.preview-wrapper .col-sm-6 {
  width: 100% !important;
}

.pill-list {
  display: inline-block;
}

.selection-info {
  font-size: 0.8em;
  color: #777;
}
</style>
