<template lang="pug">
.time-period-dropdown
  .title(v-if="showTitle") {{ title }}
  .dropdown(:class="{open: menuOpen}")
    .dropdown-toggle
      .btn.btn-sm.btn-primary(:class="btnClasses" @click="menuOpen = !menuOpen")
        | {{ label }} 
        span.caret
    .dropdown-menu
      li.simple-spinner(v-if="!availableItems")
        .spinner
      div(v-if="availableItems")
        li.dropdown-item(@click="selectItem(item)", v-for="item in items")
          | {{ getLabel({ type: menuItemKey(item) }) }}
        li.divider(v-if="restItems.length > 0")
        li.dropdown-submenu(v-if="restItems.length > 0")
          a(@click.stop="moreMenuOpen = !moreMenuOpen") {{ 'filters.date_selector.more_options' | i18n }}
          ul.dropdown-menu(:class="{open: moreMenuOpen}")
            .scroll-area
              .dropdown-item(@click="selectItem(item)", v-for="item in restItems")
                | {{ getLabel({ type: menuItemKey(item) }) }}
        li.divider(v-if="custom")
        li.dropdown-submenu(v-if="custom")
          a(@click.stop="customMenuOpen = !customMenuOpen") {{ 'filters.date_selector.custom' | i18n }}
          ul.dropdown-menu.custom-menu(:class="{open: customMenuOpen}" @click.stop="")
            calendar(
              is-expanded
              :attributes="customCalendarAttributes", 
              :first-day-of-week="firstDayOfWeek + 1",
              :locale="locale",
              @dayclick="onDayClick"
            )
        div(v-if="availableSeries.length > 0")
          li.divider
          li.dropdown-submenu
            a(@click.stop="seriesMenuOpen = !seriesMenuOpen") {{ 'filters.date_selector.time_series_type' | i18n }}
            ul.dropdown-menu(:class="{open: seriesMenuOpen}")
              li.dropdown-item(@click="selectSeries(series)", v-for="series in availableSeries")
                | {{ series.name }}
</template>

<script lang="ts">
import { Dropdown } from "uiv";
import Calendar from "v-calendar/lib/components/calendar.umd";
import moment from "moment";
import TranslationService from "../core/translation.service";
import i18n from "../i18n";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import MenuItem, { menuItemKey } from "../interfaces/menu-item";
import _ from "lodash";

@Component({
  components: {
    Dropdown,
    Calendar,
  },
})
export default class TimePeriodDropdown extends Vue {
  @Prop()
  availableItems: MenuItem[];

  @Prop()
  availableSeriesTypes: string[];

  @Prop()
  default: any;

  @Prop()
  value: any;

  @Prop({ default: true })
  showTitle: boolean;

  @Prop({ default: true })
  custom: boolean;

  @Prop({ default: 5 })
  showItems: number;

  @Prop({ default: i18n.t("dashboard_custom.report_editor.time") })
  title: string;

  menuOpen = false;
  moreMenuOpen = false;
  customMenuOpen = false;
  seriesMenuOpen = false;
  firstDayOfWeek = window.zoinedContext.firstDayOfWeek;
  locale = i18n.locale;
  customRangeDate = null;

  get btnClasses() {
    return {
      default: this.default && !this.value,
    };
  }

  get label() {
    if (this.value) {
      return this.getLabel(this.value);
    } else if (this.default) {
      return this.getLabel(this.default);
    } else {
      return "Select";
    }
  }

  get items() {
    return _.take(this.availableItems, this.showItems);
  }

  get restItems() {
    return _.drop(this.availableItems, this.showItems);
  }

  get customCalendarAttributes() {
    return [
      {
        highlight: true,
        dates: this.customRangeDate,
      },
    ];
  }

  get availableSeries() {
    return (this.availableSeriesTypes || []).map((type) => ({
      type,
      name: new TranslationService().seriesTitle(type),
    }));
  }

  menuItemKey(item) {
    return menuItemKey(item);
  }

  selectItem(item) {
    const current = this.value || this.default || {};
    this.$emit("update", { ...current, type: menuItemKey(item) });
    this.menuOpen = false;
  }

  selectSeries({ type }) {
    const current = this.value || this.default || {};
    this.$emit("update", { ...current, series: type });
    this.menuOpen = false;
  }

  getLabel(selection) {
    return new TranslationService().selectionTitle(selection, this.availableItems);
  }

  onDayClick({ date }) {
    if (this.customRangeDate) {
      this.selectCustomDates(this.customRangeDate, date);
      this.menuOpen = false;
    } else {
      this.customRangeDate = date;
    }
  }

  selectCustomDates(d1, d2) {
    let start = moment(d1);
    let end = moment(d2);
    [start, end] = start.isAfter(end) ? [end, start] : [start, end];

    const current = this.value || this.default || {};
    const selection = {
      ...current,
      type: "custom",
      start_time: start.format("YYYY-MM-DD"),
      end_time: end.format("YYYY-MM-DD"),
    };

    this.$emit("update", selection);
  }

  @Watch("menuOpen")
  onMenuOpen() {
    if (!this.menuOpen) {
      this.customRangeDate = null;
      this.customMenuOpen = this.moreMenuOpen = this.seriesMenuOpen = false;
    }
  }
  @Watch("customMenuOpen")
  onCustomMenuOpen() {
    if (this.customMenuOpen) {
      this.moreMenuOpen = this.seriesMenuOpen = false;
    }
  }

  @Watch("moreMenuOpen")
  onMoreMenuOpen() {
    if (this.moreMenuOpen) {
      this.customMenuOpen = this.seriesMenuOpen = false;
    }
  }

  @Watch("seriesMenuOpen")
  onSeriesMenuOpen() {
    if (this.seriesMenuOpen) {
      this.moreMenuOpen = this.customMenuOpen = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.time-period-dropdown {
  display: inline-block;
  margin-bottom: 10px;

  .dropdown {
    display: inline-block;
  }

  &:not(:last-child) {
    margin-right: 20px;
  }

  .title {
    display: inline-block;
    margin-right: 15px;
  }

  .btn.default {
    opacity: 0.5;
  }

  .dropdown-item {
    padding: 7px 10px;
    border-radius: 5px;
    color: #333;
    white-space: nowrap;
    margin: 4px;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background-color: #cceeff;
      cursor: pointer;
    }
  }

  .dropdown-submenu {
    position: relative;
    .dropdown-menu {
      top: 0;
      left: 100%;
      .scroll-area {
        max-height: 400px;
        overflow-y: auto;
      }
      &.open {
        display: block;
      }
      @media (max-width: 767px) {
        position: absolute;
        left: 0;
      }
    }
  }

  .vc-container {
    border: 0;
  }
}
</style>
