<template lang="pug">
.btn-group.flex-row
  label.btn.btn-default.btn-sm.btn-icon(:class="{active: value === 'column'}", @click="$emit('input', 'column')")
    i.icon-zoined-vertical-chart
  label.btn.btn-default.btn-sm.btn-icon(:class="{active: value === 'bar'}", @click="$emit('input', 'bar')")
    i.icon-zoined-horizontal-chart
</template>
<script>
export default {
  props: {
    value: String
  }
};
</script>

