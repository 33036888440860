<template lang="pug">
.zoined-report
  zoined-report-row(
    v-for='(components, index) in componentRows'
    :key="index",
    :components="components",
    :detail-component="detailComponent",
    :filter-configuration="filterConfiguration",
    :chart-options="chartOptions",
    :table-config="tableConfig",
    :custom="custom",
    :preview="preview",
    :public="public",
    :overrides="overrides",
    @toggle-details="setDetailComponent",
    @component-updated="onComponentUpdated"
    @filter-configuration-updated="$emit('filter-configuration-updated', $event)",
    @excel-export-component="$emit('excel-export-component', $event)"
    @update-overrides="overrides = $event"
  )
</template>

<script lang="ts">
import Vue from "vue";
import zoinedReportRow from "./zoined-report-row.vue";

const getMaxRowSpan = () => (window.innerWidth < 768 ? 3 : 12);

export default {
  components: {
    zoinedReportRow,
  },
  props: {
    components: {
      type: Array,
      required: true,
    },
    filterConfiguration: {
      type: Object,
      required: false,
    },
    chartOptions: {
      type: Object,
      required: false,
    },
    tableConfig: {
      type: Object,
      required: false,
    },
    custom: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    public: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      detailComponent: null,
      maxRowSpan: getMaxRowSpan(),
      overrides: {},
    };
  },
  computed: {
    componentRows() {
      const rows = [];

      let currentRow;
      let rowSpan = 0;
      this.components.forEach((component) => {
        const componentSpan = component.span || 12;
        if (!currentRow || rowSpan + componentSpan > this.maxRowSpan) {
          currentRow = [];
          rowSpan = 0;
          rows.push(currentRow);
        }
        currentRow.push(component);
        rowSpan += componentSpan;
      });

      return rows;
    },
  },
  methods: {
    setDetailComponent(component) {
      this.detailComponent = component == this.detailComponent ? null : component;
    },
    onComponentUpdated({ component, updatedComponent }) {
      const components = this.components.map((c) => (c == component ? updatedComponent : c));
      this.$emit("components-updated", components);
    },
    handleResize() {
      this.maxRowSpan = getMaxRowSpan();
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
