<template lang="pug">
.btn.btn-sm(:class="buttonStyleClass" @click="$emit('click', $event)")
  slot
  i.fa.ml-sm(:class="[menuOpen ? 'fa-chevron-up' : 'fa-chevron-down']")
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class DropdownSelectButton extends Vue {
  @Prop()
  menuOpen: boolean;

  @Prop({ default: "default" })
  buttonStyle: string;

  get buttonStyleClass() {
    return `btn-${this.buttonStyle}`;
  }
}
</script>
