<template lang="pug">
.form.form-inline
  .form-group.form-group-sm
    select.form-control(v-model="model")
      option(value="balanced") {{ 'components.trend_sort_selector.balanced_sort' | i18n }}
      option(value="trend") {{ 'components.trend_sort_selector.trend_sort' | i18n }}
      option(value="absolute") {{ 'components.trend_sort_selector.absolute_change_sort' | i18n }}
</template>
<script>
export default {
  props: {
    value: String
  },
  computed: {
    model: {
      get() {
        return this.value || "balanced";
      },
      set(newValue) {
        this.$emit("input", newValue);
      }
    }
  }
};
</script>
