<template lang="pug">
.edit-report-page(v-if="loaded")
  .mt-md
  report-editor(ref="editor" :default-report="report" :default-my-report="myReport")
spinner(v-else)
</template>

<script lang="ts">
import i18n from "../i18n";
import Vue from "vue";
import Component from "vue-class-component";
import Report from "../model/report";
import MyReport from "../model/my-report";
import spinner from "../components/spinner.vue";
import Mutations from "../store/mutations";
import { Prop, Ref } from "vue-property-decorator";
import ReportContext from "../model/report-context";
import { makeApiInstance } from "../api/instance";
import ReportEditor from "./report-editor.vue";
import FilterConfiguration from "../model/filter-configuration";
import ChartOptions from "../model/chart-options";
import TableConfig from "../model/table-config";

@Component({
  components: { spinner, ReportEditor },
})
export default class EditCustomReportPage extends Vue {
  @Ref("editor")
  editor: ReportEditor;

  @Prop()
  name: string;

  @Prop()
  myReportId: string;

  @Prop()
  duplicateId: string;

  @Prop()
  config: {
    filter_configuration?: FilterConfiguration;
    chart_options?: ChartOptions;
    table_config?: TableConfig;
  };

  report: Report = null;
  myReport: MyReport = null;

  get title() {
    if (this.myReportId) {
      return this.myReport.name;
    } else {
      return i18n.t("custom_report.new_report");
    }
  }

  get loaded() {
    return this.report && this.myReport;
  }

  async created() {
    if (this.myReportId) {
      const reportContext: ReportContext = await makeApiInstance()
        .get("/api/v1/reports/report_context", { params: { report_name: this.name, my_report_id: this.myReportId } })
        .then((response) => response.data);
      this.report = reportContext.report;
      this.myReport = reportContext.my_report;
    } else if (this.duplicateId) {
      const reportContext: ReportContext = await makeApiInstance()
        .get("/api/v1/reports/report_context", { params: { report_name: this.name, my_report_id: this.duplicateId } })
        .then((response) => response.data);
      this.report = reportContext.report;
      this.myReport = reportContext.my_report;
      this.myReport.name = `Copy of ${this.myReport.name}`;
      delete this.myReport.id;
    } else {
      const reportContext: ReportContext = await makeApiInstance()
        .get("/api/v1/reports/report_context", { params: { report_name: this.name } })
        .then((response) => response.data);
      this.report = reportContext.report;
      this.myReport = { ...reportContext.my_report, filters: reportContext.filter_configuration };
    }

    // Overrides
    if (this.config?.filter_configuration) {
      this.myReport.filters = this.config.filter_configuration;
    }
    if (this.config?.chart_options) {
      this.myReport.control_state = this.config.chart_options;
    }
    if (this.config?.table_config) {
      this.myReport.table_config = this.config.table_config;
    }

    this.$store.commit(Mutations.setTitle, this.title);
  }

  beforeRouteUpdate(to, from, next) {
    this.handleRouteChange(next);
  }

  beforeRouteLeave(to, from, next) {
    this.handleRouteChange(next);
  }

  handleRouteChange(next) {
    let confirmed = true;

    if (this.editor?.hasChanges) {
      confirmed = confirm(i18n.t("confirm.unsaved_changes_confirmation"));
    }

    if (confirmed) {
      this.$store.commit(Mutations.clearTitle);
      next();
    } else {
      next(false);
    }
  }
}
</script>
