import { formatNumber } from "../formatters";
import { weatherTooltipDesc } from "./weather";
import _ from "lodash";
import "whatwg-fetch";
import qs from "qs";

export function tooltipFormatter(options, config = {}) {
  const type = options.chart.name || options.chart.type;

  switch (type) {
    case "heatmap":
      return heatmapFormatter(options);
    case "bubble":
      return bubbleFormatter(options);
    case "scatter":
      return scatterFormatter(options);
    case "stacked":
      return stackedFormatter(options);
    default:
      if (options.tooltip.shared) return sharedTooltipFormatter(options, config);
      else return null;
  }
}

export function heatmapFormatter(data) {
  if (__guard__(data.extra != null ? data.extra.filters : undefined, (x1) => x1.grouping) === "minute-hour") {
    // Show hour and minute.
    return function() {
      let s = `<b>${this.point.y}:${this.series.xAxis.categories[this.point.x]}</b><br/>${
        this.series.name
      }: ${formatNumber(this.point.value, this.series.tooltipOptions.valueDecimals || 0)}${this.series.tooltipOptions
        .valueSuffix || ""}`;
      if (this.point.absolute_value) {
        s += `; ${formatNumber(this.point.absolute_value, 0)}${this.series.tooltipOptions.absoluteValueSuffix || ""}`;
      }
      return s;
    };
  } else if (__guard__(data.extra != null ? data.extra.filters : undefined, (x) => x.grouping_y) === "hour") {
    // Show date, and hour range.
    return function() {
      let s = `<b>${this.series.xAxis.categories[this.point.x]} ${this.point.y}:00-${this.point.y + 1}:00</b><br/>${
        this.series.name
      }: ${formatNumber(this.point.value, this.series.tooltipOptions.valueDecimals || 0)}${this.series.tooltipOptions
        .valueSuffix || ""}`;
      if (this.point.absolute_value) {
        s += `; ${formatNumber(this.point.absolute_value, 0)}${this.series.tooltipOptions.absoluteValueSuffix || ""}`;
      }
      return s;
    };
  } else {
    // Show x and y categories.
    return function() {
      const xName = this.series.xAxis.categories[this.point.x];
      const yName = this.series.yAxis.categories[this.point.y];
      let s = `<b>${xName} ${yName}</b><br/>${this.series.name}: ${formatNumber(
        this.point.value,
        this.series.tooltipOptions.valueDecimals || 0
      )}${this.series.tooltipOptions.valueSuffix || ""}`;
      if (this.point.absolute_value) {
        s += `; ${formatNumber(this.point.absolute_value, 0)}${this.series.tooltipOptions.absoluteValueSuffix || ""}`;
      }
      return s;
    };
  }
}

export function bubbleFormatter(data) {
  return function() {
    const name = this.point.tooltipName || this.point.name;
    return `\
      <b>${name}</b>
      <br/>
      ${data.extra.z.name}: ${formatNumber(this.point.z, data.extra.z.tooltipPrecision || 0)} ${data.extra.z.unit}
      <br/>
      ${data.extra.x.name}: ${formatNumber(this.point.x, data.extra.x.tooltipPrecision || 0)} ${data.extra.x.unit}
      <br/>
      ${data.extra.y.name}: ${formatNumber(this.point.y, data.extra.y.tooltipPrecision || 0)} ${data.extra.y.unit}\
    `;
  };
}

/**
 * Fetches more information about the basket.
 *
 * TODO: Shouldn't be in common formatters.
 */
export function scatterFormatter(data) {
  let count = 0;
  const delayedFetch = _.debounce((tooltip, id, date, lines) => {
    const currentCount = ++count;
    fetch(
      "/api/v1/charts/basket_scatter/tooltip?" +
        qs.stringify({
          chart_token: window.chart_token,
          "filter:basket_id": id,
        }),
      {
        credentials: "same-origin",
      }
    )
      .then((res) => {
        return res.text();
      })
      .then((tooltipHtml) => {
        if (currentCount == count) {
          lines.push(`<span class='date'>${date}</span><div id='product-list-container'>${tooltipHtml}</div>`);
          tooltip.label.attr({ text: lines.join("<br/>") });
        }
      });
  }, 200);

  return function(tooltip) {
    const name = this.point.tooltipName || this.point.name;

    const lines = [
      `<b>${name}</b>`,
      `<span class='basket_sales'>${Math.round(this.point.y * 100) / 100} ${this.point.currency}</span>  (${
        this.point.sales_pcs
      } pcs)`,
    ];

    delayedFetch(tooltip, this.point.basket_id, this.point.date, lines);

    return lines.join("<br/>");
  };
}

export function stackedFormatter(data) {
  const stacking = _.get(data, "plotOptions.column.stacking");
  return function() {
    return `<b>${this.point.category}</b><br/>${this.point.series.name}: ${
      stacking == "percent" ? formatNumber(this.point.percentage, 2) + "%; " : ""
    }${formatNumber(this.point.y)}${data.tooltip.valueSuffix}`;
  };
}
/**
 * Default formatter when we are showing same tooltip for all serieses.
 */
export function sharedTooltipFormatter(data, config) {
  const { newLayout } = config;
  return function() {
    let s = `<div class='tooltip-header'><b>${this.points[0].point.name}</b></div>`;
    if (newLayout) {
      s += "<table class='tooltip-points'>";
    }
    _.each(this.points, function(point) {
      s += newLayout ? newLayoutFormatPoint(point) : formatPoint(point);
    });
    if (newLayout) {
      s += "</table>";
    }
    if (data.weather) {
      s += weatherTooltipDesc(this, data);
    }

    return s;
  };
}

function formatPoint(point) {
  point = point.point || point;
  let s = `<div>${point.series.name}: ${point.tooltipValue ||
    formatNumber(point.y, point.series.tooltipOptions.valueDecimals || 0)}${point.series.tooltipOptions.valueSuffix ||
    ""}`;
  if (point.absolute_value) {
    s += `; ${formatNumber(point.absolute_value, 0)}${point.series.tooltipOptions.absoluteValueSuffix || ""}`;
  }
  // If there is a change % (from comparison to selection), show it.
  if (point.diff) {
    let sign = point.diff > 0 ? "+" : "";
    s += ` (${sign}${formatNumber(point.diff, 1)}%)`;
  }
  s += "</div>";
  return s;
}

function newLayoutFormatPoint(point) {
  point = point.point || point;
  const tooltipValue = point.tooltipValue || formatNumber(point.y, point.series.tooltipOptions.valueDecimals || 0);
  const valueSuffix = point.series.tooltipOptions.valueSuffix || "";
  let s = "<tr>";
  s += `<td><div class='tooltip-legend' style='background-color: ${point.color};'></div></td>`;
  s += `<td>${tooltipValue}${valueSuffix}`;
  if (point.absolute_value) {
    const absoluteValue = formatNumber(point.absolute_value, 0);
    const absoluteValueSuffix = point.series.tooltipOptions.absoluteValueSuffix || "";
    s += `; ${absoluteValue}${absoluteValueSuffix}`;
  }
  s += "</td>";
  // If there is a change % (from comparison to selection), show it.
  if (point.diff) {
    const change = point.diff > 0 ? "positive" : "negative";
    const diff = formatNumber(point.diff, 1);
    s += `<td><div class='tooltip-change-${change}'><i/></div></td>`;
    s += `<td>${diff}%</td>`;
  }
  s += "</tr>";
  return s;
}
function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined;
}
