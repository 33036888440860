<template lang="pug">
.cross-tab-settings-modal
  a.btn.btn-default.btn-sm.btn-icon(@click="open=true")
    i.fa.fa-table.fa-lg
  modal(v-model="open", :append-to-body="true", :title="title", size="lg")
    h2 {{ 'table_settings.settings' | i18n }}
    .row
      .col-md-4
        label {{ 'table_settings.combine_groupings_label' | i18n }}
        toggle-button(v-model="config.combine_groupings")
    h2 {{ 'table_settings.column_visibility' | i18n }}
    table.column-configuration
      tr
        th {{ 'filter.config.metrics' | i18n }}
        th {{ 'table_settings.serie' | i18n }}
        th {{ 'table_settings.column' | i18n }}
        th(v-if="columnGrouping") {{ columnGroupingTitle }}
        th {{ 'report.snippet.table.total' | i18n }}
      tr(v-for="column in config.columns")
        td {{ metricLabel(column.metric) }}
        td {{ seriesLabel(column.series) }}
        td {{ columnLabel(column.column) }}
        td(v-if="columnGrouping")
          input(type="checkbox" v-model="column.visibility[columnGrouping]")
        td
          input(type="checkbox" v-model="column.visibility.total")
    h2 {{ 'table_settings.total_rows' | i18n }}
    table.total-visibility
      tr
        th {{ 'filter.config.grouping' | i18n }}
        th {{ 'table_settings.show_total_rows' | i18n }}
      tr(v-for="item in config.totals" :key="item.grouping")
        td {{ item.grouping == "total" ? reportTotalTitle : groupingTitle(item.grouping) }}
        td
          input(type="radio" :name="'total_visibility['+item.grouping+']'" value="before" v-model="item.total_visibility")
          label {{ 'table_settings.total_visibility.before' | i18n }}
          input(type="radio" :name="'total_visibility['+item.grouping+']'" value="after" v-model="item.total_visibility")
          label {{ 'table_settings.total_visibility.after' | i18n }}
          input(type="radio" :name="'total_visibility['+item.grouping+']'" value="hidden" v-model="item.total_visibility")
          label {{ 'table_settings.total_visibility.hidden' | i18n }}


    div(slot="footer")
      button.btn.btn-default(@click="open = false") {{ 'actions.cancel' | i18n }}
      button.btn.btn-primary(@click="update") {{ 'actions.save' | i18n }}
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Modal } from "uiv";
import { Prop, Watch } from "vue-property-decorator";
import i18n from "../i18n";
import TranslationService from "../core/translation.service";
import { comparisonToMenuKey, menuKeyToComparison } from "../lib/menu-helper";
import configPillList from "./config-pill-list.vue";
import toggleButton from "./toggle-button.vue";
import _ from "lodash";
import TableConfig from "../model/table-config";
import Actions from "../store/actions";

@Component({
  components: { Modal, configPillList, toggleButton },
})
export default class CrossTabSettingsModal extends Vue {
  @Prop()
  tableConfig: TableConfig;

  // flat filter config
  @Prop()
  filterConfiguration: any;

  open = false;

  config = null;
  count = 0;
  get title() {
    return "Table settings";
  }

  get columnGrouping() {
    const columnGrouping = _.find(
      _.toPairs<any>(this.filterConfiguration.column_grouping),
      // eslint-disable-next-line no-unused-vars
      ([key, config]) => config.enabled
    );
    if (columnGrouping) {
      return columnGrouping[0];
    } else {
      return null;
    }
  }

  get columnGroupingTitle() {
    return this.groupingTitle(this.columnGrouping);
  }

  get reportTotalTitle() {
    return i18n.t("table_settings.report_total");
  }

  get comparisons() {
    return this.filterConfiguration.comparisons.filter(({ enabled }) => enabled);
  }

  get metrics() {
    return (
      _.toPairs<any>(this.filterConfiguration.metrics)
        // eslint-disable-next-line no-unused-vars
        .filter(([key, { enabled }]) => enabled)
        .map(([key]) => key)
    );
  }

  get dependencies() {
    return [this.filterConfiguration, this.tableConfig];
  }

  get metricsParams() {
    return this.$store.getters.getParameters("metrics");
  }

  get groupingParams() {
    return this.$store.getters.getParameters("grouping");
  }

  metricLabel(metric) {
    if (this.metricsParams) {
      const metricParam = this.metricsParams.find(({ key }) => key === metric);
      if (metricParam) {
        return metricParam.name;
      }
    }

    return i18n.t(`metadata.metrics.${metric}`);
  }

  groupingTitle(groupingKey) {
    const groupings = this.$store.getters.getParameters("grouping");
    const grouping = groupings?.find(({ key }) => key == groupingKey);
    return (grouping && grouping.name) || i18n.t(`filter.config.${groupingKey}`);
  }

  seriesLabel(series) {
    if (series === "selection") {
      return i18n.t("selection");
    } else {
      return new TranslationService().comparisonTitle(menuKeyToComparison(series));
    }
  }

  columnLabel(column) {
    if (column == "actual") {
      return i18n.t("filters.date_selector.actual");
    } else {
      return i18n.t(`report.snippet.table.${column}`);
    }
  }

  show() {
    this.open = true;
  }

  update() {
    this.$emit("update", this.config);
    this.open = false;
  }

  created() {
    this.buildConfig();
  }

  @Watch("dependencies")
  buildConfig() {
    let tableConfig = this.tableConfig || {};
    const columnConfig = (tableConfig.columns || []).reduce((config, item) => {
      const { metric, series, column, visibility } = item;
      config[metric] = config[metric] || {};
      config[metric][series] = config[metric][series] || {};
      config[metric][series][column] = visibility;
      return config;
    }, {});

    const columns = [];
    const selectionColumns = ["actual"];
    const comparisonColumns = ["actual"];
    if (this.filterConfiguration.show_percentual_change) {
      comparisonColumns.push("change_percent");
    }
    if (this.filterConfiguration.show_absolute_change) {
      comparisonColumns.push("change_absolute");
    }
    if (this.filterConfiguration.show_index) {
      comparisonColumns.push("change_index");
    }
    if (this.filterConfiguration.proportions) {
      selectionColumns.push("proportion");
      comparisonColumns.push("proportion");
    }

    this.metrics.forEach((metric) => {
      selectionColumns.forEach((column) => {
        const series = "selection";
        columns.push({
          metric,
          column,
          series,
          visibility: {
            total: true,
            [this.columnGrouping]: true,
            ..._.get(columnConfig, [metric, series, column]),
          },
        });
      });
      this.comparisons.forEach((comparison) => {
        const series = comparisonToMenuKey(comparison);
        comparisonColumns.forEach((column) => {
          columns.push({
            metric,
            column,
            series,
            visibility: {
              total: true,
              [this.columnGrouping]: true,
              ..._.get(columnConfig, [metric, series, column]),
            },
          });
        });
      });
    });

    const totalConfig = (tableConfig.totals || []).reduce((config, item) => {
      return {
        ...config,
        [item.grouping]: item.total_visibility,
      };
    }, {});

    const groupingKeys = _.toPairs<any>(this.filterConfiguration.grouping)
      // eslint-disable-next-line no-unused-vars
      .filter(([key, config]) => config.enabled)
      .slice(0, -1)
      .map(([grouping]) => grouping)
      .concat(["total"]);

    const totals = groupingKeys.map((grouping) => ({
      grouping,
      total_visibility: totalConfig[grouping] || "before",
    }));

    const combine_groupings = !!tableConfig.combine_groupings;

    this.config = { columns, totals, combine_groupings };
  }
}
</script>

<style lang="scss" scoped>
.cross-tab-settings-modal {
  display: inline-block;
}

label {
  display: block;
}
table.column-configuration {
  width: 100%;
  td {
    text-align: left;
  }
}
table.total-visibility {
  width: 100%;
  label {
    display: inline-block;
    font-weight: normal;
    margin-right: 10px;
    margin-left: 5px;
  }
}
</style>
