import { render, staticRenderFns } from "./round-values-selector.vue?vue&type=template&id=05ed1695&scoped=true&lang=pug"
import script from "./round-values-selector.vue?vue&type=script&lang=js"
export * from "./round-values-selector.vue?vue&type=script&lang=js"
import style0 from "./round-values-selector.vue?vue&type=style&index=0&id=05ed1695&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05ed1695",
  null
  
)

export default component.exports