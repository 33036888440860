const Mutations = {
  get clearReportContext() {
    return "reportContext/clear";
  },

  get setFilterConfiguration() {
    return "reportContext/setFilterConfiguration";
  },

  get setChartOptions() {
    return "reportContext/setChartOptions";
  },

  get setTableConfig() {
    return "reportContext/setTableConfig";
  },

  get setHasChanges() {
    return "reportContext/setHasChanges";
  },

  get fetchReportContextSuccess() {
    return "reportContext/fetchSuccess";
  },

  get fetchNavigationSuccess() {
    return "navigation/fetchSuccess";
  },

  get openFlyoverFilters() {
    return "flyoverFilters/open";
  },

  get closeFlyoverFilters() {
    return "flyoverFilters/close";
  },

  get setRoleData() {
    return "role/setRoleData";
  },

  get setDefaultDashboardId() {
    return "role/setDefaultDashboardId";
  },

  get setPreferences() {
    return "preferences/setPreferences";
  },

  get setTitle() {
    return "currentPage/setTitle";
  },

  get clearTitle() {
    return "currentPage/clearTitle";
  },
};

export default Mutations;
