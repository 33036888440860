<template>
  <div class="zoined-vue-chart">
    <vue-highcharts v-if="mergedOptions" :options="mergedOptions" :cleanUpdate="cleanUpdate" ref="chart"></vue-highcharts>
  </div>
</template>

<script>
import VueHighcharts from "./vue-highcharts.vue";
import { formatNumber } from "../lib/formatters";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import Heatmap from "highcharts/modules/heatmap";
import { tooltipFormatter } from "../lib/highcharts/tooltip";
import _ from "lodash";

HighchartsMore(Highcharts);
Heatmap(Highcharts);

// Disable Highcharts animations if rendering the page for PDF generation.
if (navigator.userAgent.match(/\b(PhantomJS|HeadlessChrome)\b/)) {
  Highcharts.setOptions({
    plotOptions: {
      series: {
        animation: false,
      },
    },
  });
}

Highcharts.setOptions({
  lang: {
    decimalPoint: ",",
    thousandsSep: "\u00a0",
  },
  chart: {
    style: {
      fontFamily: "Open Sans, sans-serif",
    },
  },
});

function addFormatters(options, config) {
  if (options.chart.type == "pie") {
    return;
  }
  if (!options.plotOptions) { options.plotOptions = {}; }
  if (!options.plotOptions.series) { options.plotOptions.series = {}; }
  if (!options.plotOptions.series.dataLabels) { options.plotOptions.series.dataLabels = {}; }
  if (!(options.plotOptions.series.dataLabels.formatter || options.plotOptions.series.dataLabels.format)) {
    options.plotOptions.series.dataLabels.formatter = function () {
      return formatNumber(
        options.chart.type === "heatmap" ? this.point.value : this.y,
        this.series.userOptions.precision || config.dataPointPrecision || 0
      );
    };
    options.plotOptions.series.dataLabels.format = null;
  }
  if (!options.tooltip) { options.tooltip = {}; }
  if (!options.tooltip.formatter) { options.tooltip.formatter = tooltipFormatter(options, config); }
  options.tooltip.useHTML = true;
}

export default {
  components: {
    VueHighcharts,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    cleanUpdate: {
      type: Boolean,
      default: false,
    },
    formatterConfig: {
      type: Object,
      default: function () {
        return { dataPointPrecision: 0 };
      },
    },
    extraRenderFunc: {
      // Function for rendering extra objects on chart
      // should return array of objects for removal when the function changes
      type: Function,
    },
  },
  data() {
    return {
      mergedOptions: null,
      extraRenderedObjects: [],
    };
  },
  methods: {
    cleanExtras() {
      _.map(this.extraRenderedObjects, (o) => o.destroy());
      this.extraRenderedObjects = [];
    },
    updateMergedOptions() {
      const options = _.cloneDeep(this.options);
      addFormatters(options, this.formatterConfig);
      _.each(
        options.series,
        (s) =>
        (s.data = s.data.map((p) => {
          const events = {
            click: () => {
              this.$emit("point-click", p);
            },
          };
          if (_.isObject(p)) {
            if (_.isArray(p)) {
              return { x: p[0], y: p[1], value: p[2], events };
            } else {
              return _.merge({ events, ...p });
            }
          } else {
            return { y: p, events };
          }
        }))
      );

      if (!options.chart) { options.chart = {}; }
      if (!options.chart.events) { options.chart.events = {}; }

      if (!options.chart.height) {
        if (options.chart.type == "bar" || options.chart.inverted) {
          const barHeight = 20;
          const spacingHeight = 10;
          const baseHeight = 120;
          const xAxis = _.isArray(options.xAxis) ? options.xAxis[0] : options.xAxis;
          const categoryCount = _.get(xAxis, "categories", []).length;
          const seriesCount = options.series.length;
          if (categoryCount > 0) {
            options.chart.height = baseHeight + barHeight * categoryCount * seriesCount + spacingHeight * categoryCount;
          }
        }
      }

      const renderFunc = this.extraRenderFunc;
      const that = this;

      options.chart.events.load = function () {
        if (renderFunc) { that.extraRenderedObjects = renderFunc(this, that.options); }
      };

      options.chart.events.redraw = (event) => {
        this.$emit("redraw", event.target);
      };

      this.mergedOptions = options;
    },
  },
  watch: {
    mergedOptions: function (opts) {
      this.cleanExtras();
    },
    options: {
      immediate: true,
      handler(newOpts, oldOpts) {
        if (!_.isEqual(newOpts, oldOpts)) {
          this.updateMergedOptions();
        }
      },
    },
  },
};
</script>

<style scoped>
.highcharts {
  height: 100%;
}
</style>
<style>
.zoined-vue-chart {
  height: 100%;
}
</style>
